import { useEffect, useRef } from "react";

export function useEffectOnce(
  effect: () => void,
  deps: unknown[] = [], // needed?
  cleanup = () => {
    return;
  },
) {
  // A flag indicating whether the effect has been executed or not.
  const isExecuted = useRef(false);

  useEffect(() => {
    // If executed already, skip it this time.
    if (isExecuted.current) {
      return;
    }

    // Otherwise, just call it as normal.
    effect();

    // And set the flag to true.
    isExecuted.current = true;

    return cleanup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
